.link {
        
    &:visited,
    &:link{
    text-decoration: none;
    font-family: Montserrat;    
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    
    }

    &-terminos {
        
        font-family: Montserrat;    
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        color: black;
    }
}