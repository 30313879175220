.btn {
    border: none;
    font-size: 2rem;
    width: 4rem;
    height: 4rem;
    border-radius: 4rem;
    &.activeBTN{
        margin-left: 5px;
        margin-top: 20px;
        background: #fe64b0;
        color: #FFFFFF;
        transition: .2s all;
    }
    &.desactiveBTN{
        margin-left: 5px;
        margin-top: 20px;
        background: #bb5e8c;
        color: rgb(185, 185, 185);
        transition: .2s all;
    }

    &-siguiente {
        width: 250px;
        height: auto;
        padding: 0.8rem 2rem;
        background: #fe64b0;
        color: #FFFFFF;
        border-radius: 100px;
        transition: .2s all;
        box-shadow: 0px -2px 0px 4px #a02a65cc;
        display: block;
        max-width: 90%;
        margin: auto;
        @media only screen and (max-width: 768px) {
            width: 500px;
        }
        @media only screen and (max-width: 425px) {
            width: 100%;
        }

        &:visited,
        &:active{
            background: #ec9bc3;
        }

        &:hover,
        &:focus {
            background: #ec9bc3;
        }
      
        &:disabled {
        background: grey;
        color: rgb(185, 185, 185);
        }
    }

    &-perfil{
        height: 9.6rem;
        width: 9.2rem;
        padding: 2rem;
        background: #BDBDBD;
        border-radius: 1.6rem;
        &:not(:last-child){
        margin-right: 1rem;
        }

        &-activo {
            height: 9.6rem;
            width: 9.2rem;
            padding: 2rem;
            border-radius: 1.6rem;
            background: #2D9CDB;
            color: white;
            &:not(:last-child){
                margin-right: 1rem;
            }
        }

    }

    &-centros {
        width: 37.3rem;
        height: 20.7rem;
        padding: 1rem;
        font-size: 2rem;
        border-radius: 3.5rem;
        text-align: center;
        background-color: #f4eff9;
        border: 2px solid #89288c;

        @media (max-width: 425px){
            width: 100%;
        }

        &:hover,
        &:active{
            background: #89288c;
            color: white;
        }

        h3 {
            margin-bottom: 1rem;
        }

        &__activo {
            width: 37.3rem;
            height: 20.7rem;
            padding: 1rem;
    
            font-size: 2rem;
            border-radius: 3.5rem;
            text-align: center;
            background: #89288c;
            color: white;
        }
    }

    &-edit {
        background: transparent;
        
        font-size: 1rem;
        // padding: .5rem;
        border: none;
        cursor: pointer;

        &:active {
            color: black;
        }

        &:hover {
            color: white;
            font-weight: 600;
        }

        img{
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    &-generar {
        width: 25%;
        max-width: 33%;
        padding: 1rem 0;
        font-size: 2rem;
        background: #fe64b0;
        box-shadow: 0px -2px 0px 4px #a02a65cc;
        color: white;
        border-radius: 100px;
        @media only screen and (max-width: 768px) {
            width: 50%;
            max-width: 50%;
            margin-bottom: 2rem;
        }
        @media only screen and (max-width: 425px) {
            width: 90%;
            max-width: 90%;
            margin-right: 0;
        }
    }

    &-descargar {
        width: 25%;
        max-width: 33%;
        padding: 1rem 0;
        font-size: 2rem;
        background: #fe64b0;
        box-shadow: 0px -2px 0px 4px #a02a65cc;
        color: white;
        border-radius: 100px;
        @media only screen and (max-width: 768px) {
            width: 50%;
            max-width: 50%;
            margin-bottom: 2rem;
        }
        @media only screen and (max-width: 425px) {
            width: 90%;
            max-width: 90%;
            margin-right: 0;
        }
    }

    &-finalizar {
        width: 25%;
        max-width: 33%;
        padding: 1rem 0;
        font-size: 2rem;
        background: #fe64b0;
        box-shadow: 0px -2px 0px 4px #a02a65cc;
        color: white;
        border-radius: 100px;
        @media only screen and (max-width: 768px) {
            width: 50%;
            max-width: 50%;
        }
        @media only screen and (max-width: 425px) {
            width: 90%;
            max-width: 90%;
            margin-right: 0;
        }
    }

    &-enviar {
        width: 25%;
        max-width: 33%;
        padding: 1rem 0;
        font-size: 2rem;
        background: #fe64b0;
        box-shadow: 0px -2px 0px 4px #a02a65cc;
        color: white;
        border-radius: 100px;
        font-size: 2rem;
        font-weight: 600;
         &:hover{
             background: #2378a8;
        }
        @media only screen and (max-width: 768px) {
            width: 50%;
            max-width: 50%;
            margin-bottom: 2rem;
        }

        &:disabled {
            color: white;
            background-color: #BDBDBD;;
        }
    }

}

.activo {
        background: #89288c;
        color: white;
}