.seguro {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media only screen and (max-width: 768px) {
        display: block;
        text-align: center;
    }
    &-form {
        @media only screen and (max-width: 768px) {
            .flex-container {
                display: flex;
                .flex-row {
                    display: block!important;
                    margin: 1rem;
                    width: 100%;
                }
            }
        }
        .flex-container {
            margin-top: 5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .flex-row {
                display: flex;
                flex-flow: row wrap;
            }
        }
        &-control {
            &1,
            &2,
            &3,
            &4,
            &5 {
                display: flex;
                flex-flow: column wrap;
                justify-content: flex-end;
                align-items: center;
                padding: 0 2rem;
                height: 14rem;
                width: 25%;
                @media only screen and (max-width: 768px) {
                    width: 80%;
                    margin: 1rem auto;
                    padding: 0;
                    height: 100%;
                    border-right: none;
                }
                @media (max-width: 425px){
                    width: 100%;
                }
                .form-wrap{
                    position: relative;
                    width: 100%;
                    span{
                        color: #89288c;
                        font-size: 2.5rem;
                        font-weight: bold;
                    }
                }
            }
            &5 {
                label {
                    margin-right: 2rem;
                }
                @media only screen and (max-width: 768px) {
                    display: flex;
                    flex-flow: column wrap;
                    justify-content: center;
                    align-items: center;
                    label {
                        margin-right: 0;
                    }
                }
            }
        }
        &-control6 {
            margin-top: 3rem;
            @media (max-width: 425px) {
                width: 100%;
            }
        }
        p.fecha-invalida {
            margin-top: 20px;
        }
        @media only screen and (max-width: 768px) {
            label {
                margin-bottom: 20px;
            }
            margin: 0;
        }
    }
    h1 {
        margin-top: 20px;
        margin-bottom: 1.5rem;
    }
}