.contacto {
    padding-top: 8.3rem;

    &-title {
        padding: 0 3rem;
        font-size: 2.4rem;
        font-weight: 600;        
        line-height: 2.5rem;
        letter-spacing: -0.015em;
    }

    &-form{
        margin: 3rem 0;
        padding: 5rem;
        // background-color: bisque;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      
        &_card {
            margin: 3rem 0;
            padding: 3rem 6rem;
            background-color: #BDBDBD;
            border-radius: 1.4rem;
            // width: 80%;
            min-width: 320px;
 
            display: flex;
            flex-flow: column wrap;
    

            &--terminos{
                display: flex;
                
                flex-flow: row nowrap;
                align-items: center;
                padding: 2rem 5rem 2rem 15rem;

                @media only screen and (max-width: 768px) {
                    padding: 0;
                }

                label {
                    margin-left: 2rem;
                }

            }
            @media only screen and (max-width: 768px) {
                padding: 0;
                width: 100%;
            }
        }
    }

    .form-control {
        margin: 1rem 0;
        
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        
      
        input[type=text], input[type=date] 
        {
            background-color: #F2F2F2;
            min-width: 32rem;
            max-width: 45rem;
            width: 50%;
            color: black;
            border-radius: 1.6rem;
        }

        input[type=email]  {
            background-color: #F2F2F2;
            min-width: 32rem;
            max-width: 45rem;
            width: 50%;
            color: black;
            border-radius: 1.6rem;
            font-size: 2.4rem;
            height: 6.7rem;
            border: none;
            text-align: center;
        }

        @media only screen and (max-width: 768px) {
            flex-flow: column wrap;
        }
        
    }
    .alert-msg {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f72f43;
        border-radius: 1.4rem;
        align-self: flex-end;
        @media only screen and (max-width: 768px) {
            align-self: center;
        }
    }
    
    p {
        margin-top: 2rem;
        padding: 2rem;
        color: greenyellow;
        background-color: green;
        border-radius: 1.4rem;
    }
}