.card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 90%;
    min-height: 75vh;
    border-radius: 1.6rem;
    font-size: 1.8rem;
    @media (max-width: 425px){
        max-width: 100%;
    }

    .flex-container{
        margin-top: 10.5rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-content: flex-end;

        button {
            padding: 2rem;
        }
    }

    .arrow-left{
        margin-right: 5px;
        margin-left: 15px;
        &.light{
            transform: rotate(180deg);
        }
      
    }
    .arrow-right{
        margin-left: 5px;
        margin-right: 15px;
        &.blod{
            transform: rotate(180deg);
        }
    }

}

.horizontal-bar {
            
    position:relative;

    &::after {
        content:"";
        width: 1px;
        background-color: transparent;
        height: 15rem;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: -50%;
        // left: 50%;
        border-left: 1px solid transparent;
        transform: translateY(-50%);
    }

    @media only screen and (max-width: 960px) {
        &::after {
            content:"";
            width: 0px;
            background-color: black;
            height: 0rem;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: -50%;
            // left: 50%;
            border-left: 1px solid black;
            transform: translateY(-50%);
        }
    }
}