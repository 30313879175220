.nav {
    padding: 0 3.6rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media(max-width:425px){
        padding: 0 1.5rem;
        flex-direction: column;
    }

    &-logo{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 2.4rem;
        line-height: 2.9rem;
        text-transform: uppercase;
    }

    .nav-inicio{
        margin-top: 10px;
        font-size: 20px;
    }
}