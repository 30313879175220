.resultado {
    padding: 3.7rem 0;
    width: 100%;
    background-position: 0% -25%;
    background-repeat: no-repeat;
    background-size: cover;
    h1{
        text-align: center;
        color: #444444;
        font-size: 2.25rem;
        text-transform: uppercase;
    }
    input[type=text] {
        height: auto !important;
        padding: 4px 0;
        border-color: transparent;
    }
    .d-flex {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: center;
        flex-direction: column;
        align-items: center;
    }
    .btn-edit {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #fe64b0;
        box-shadow: 0px -2px 0px 4px #a02a65cc;
        border-radius: 150px;
        padding: 0.5rem;
    }
    .tipo-atencion {
        display: flex;
        justify-content: center;
        margin: 40px 0;
        h3 {
            text-align: center;
        }
        .oncologo-o-multidisi {
            margin: 14px 0;
            display: flex;
            @media (max-width: 425px){
                flex-direction: column;
            }
            p{
                cursor: pointer;
                border-bottom: 4px solid transparent;
                transition: all 0.5s ease;
                &.left{
                    border-bottom-color: #89288c;
                }
                &.right{
                    border-bottom-color: #89288c;
                }
                &:last-child{
                    margin-left: 20px;
                    @media (max-width: 425px){
                        margin-left: 0;
                    }
                }
            }
            ol {
                list-style: none;
            }
            label {
                cursor: pointer;
            }
            .interrputor {
                margin-left: 10px;
                margin-right: 10px;
                height: fit-content;
                width: 50px;
                background-color: #89288c;
                border-radius: 20px;
                @media (max-width: 425px){
                    display: block;
                    margin: 10px auto;
                }
                &.left{
                    background-color: #661769;
                }
                .radio {
                    transition: 0.2s;
                    background-color: #ffffff;
                    display: flex;
                    border-radius: 50%;
                    height: 30px;
                    width: 30px;
                }
                &.right .radio{
                    transition: 0.2s;
                    transform: translateX(20px);
                }
            }
        }
    }
    &-datos {
        h1 {
            margin-top: 4rem;
            margin-bottom: 2rem;
        }
        .flex {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            @media only screen and (max-width: 768px) {
                flex-flow: column nowrap;
                &-item2 {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                }
            }
            &-item1 {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                margin-right: 5rem;
                &--p {
                    margin-top: 1rem;
                }
                @media only screen and (max-width: 768px) {
                    margin-right: 0;
                    &--p {
                        margin: 1rem 0;
                    }
                }
            }
        }
        .card1,
        .card2 {
            position: relative;
            min-width: 31rem;
            background: #f4eff9;
            border-radius: 1.6rem;
            border: 2px solid #89288c;
            padding: 2.5rem;
            display: flex;
            flex-flow: column nowrap;
            &.editable {
                background: #ffffff;
                input[type=text] {
                    background: #ffffff;
                    border-color: #89288c;
                }
            }
            @media only screen and (max-width: 768px) {
                width: 100%;
                min-width: 95%;
            }
            div.item {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                margin: .5rem 0;
            }
        }
        .card2 {
            .flex-row {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                margin: .5rem 0;
                h3{
                    @media (max-width: 425px){
                        text-align: center;
                    }
                }
            }
            .grid-container {
                margin: .5rem 0;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                @media (max-width: 425px){
                    display: flex;
                    flex-direction: column;
                }
                h4 {
                    align-self: center;
                }
            }
        } // Fin card2
    } // resutado-datos
    &-costo {
        padding-top: 3.6rem;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        //     @media only screen and (max-width: 360px) {
        //        align-items: stretch;
        //        padding: 0;
        //    }
        &::before {
            content: " ";
            border-top: 1px solid black;
            width: 100%;
            padding-top: 2rem;
        }
        &--title {
            margin-bottom: 1.5rem;
            font-size: 2.4rem;
        }
        .costo-form {
            // min-width: 320px;
            max-width: 113.2rem;
            @media only screen and (max-width: 360px) {
                padding: 0;
            }
            .form-control select {
                width: 100%;
                height: 6.7rem;
                background-image: url("../../images/drop-down.svg");
                background-repeat: no-repeat;
                background-position: center right 15px;
                background-size: 15px;
                border-radius: 3.5rem;
                border: 2px solid #89288c;
                text-align: center;
                font-size: 1.8rem;
            }
            .form-text {
                width: 31.7rem;
                font-size: 2.5rem;
                margin: 2rem 0;
                padding: 0.5rem 0;
                background-color: #f4eff9;
                border: 2px solid #89288c;
                text-align: center;
                border-radius: 1.6rem;
                @media (max-width: 425px){
                    width: 100%;
                }
            }
        }
        &_detalle {
            margin-top: 3.5rem;
            padding: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            // width: 100%;
            &--title {
                text-align: center;
                margin-bottom: 1.2rem;
            }
            // div {
            //     width: 100%;
            // }
            .flex-column {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                p {
                    // min-width: 320px;
                    float: left;
                    padding-right: 15rem;
                    @media only screen and (max-width: 360px) {
                        text-align: left;
                    }
                }
            }
            .flex-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 1rem 0;
                width: 100%;
                @media only screen and (max-width: 360px) {
                    justify-content: flex-start;
                    div:last-child {
                        padding-left: 1rem;
                    }
                }
            }
        }
        &_buttons {
            margin-top: 5rem;
            display: flex;
            flex-flow: row wrap;
            align-content: center;
            justify-content: space-around;
            align-items: center;
            @media only screen and (max-width: 768px) {
                display: flex;
                flex-flow: column wrap;
                justify-content: center;
                align-items: center;
            }
        }
    }
    &-legal {
        margin-top: 1.7rem;
        font-size: 1.05rem;
        color: #444444;
        font-weight: 400;
        text-align: center;
    }
    &-errors {
        margin-top: 1.5rem;
        min-width: 30rem;
        min-height: 15rem;
        border-radius: 1.6rem;
        background-color: #89288c;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contacto .form-control input[type=email]{
        height: 44px;
    }

    .contacto .form-control input[type=text], .contacto .form-control input[type=date] {
        height: 44px;
    }
    .contacto-form_card{
        input{
            min-height: 44px;
        }
    }
}