

.inicio {
    width: 100%;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
        margin-bottom: 3.5rem;
        font-size: 2.4rem;
        text-align: center;
    }
    .simulation-number-container {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @media (max-width: 425px){
            flex-direction: column;
        }
    }
    .simulation-number {
        margin-left: 20px;
        background: #FFFFFF;
        border-radius: 6px;
        width: 40%;
        height: 29px;
    }
    @media(max-width: 425px){
        width: 80%;
    }
    @media(max-width: 425px){
        width: 100%;
    }
    @media only screen and (max-width: 500px) {
        .simulation-number-container {
            display: flex;
            justify-content: center;
            align-items: center;
            .simulation-number {
                margin-left: unset;
            }
        }
    }
    &-text {
        margin: 30px 0;
        font-size: 2.4rem;
        &_grid {
            align-items: stretch !important;
            justify-items: center;
            text-align: center;
            @media only screen and (max-width: 990px) {
                display: flex;
                flex-flow: column wrap;
                justify-content: center;
                justify-items: center;
                .item:last-child {
                    margin-top: 5rem;
                }
            }
            .item {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                align-items: center;
                justify-content: space-between;
                min-height: 70px;
                @media (max-width: 425px) {
                    flex-direction: column;
                }
                h1 {
                    width: 38%;
                    height: 100%;
                    font-size: 3.28rem;
                    margin-right: 2%;
                    color: white;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    @media (max-width: 425px) {
                        width: 100%;
                        min-height: 50px;
                    }
                }
                p {
                    width: 60%;
                    text-align: left;
                    font-size: 1.55rem;
                    color: #444444;
                    @media (max-width: 425px){
                        width: 90%;
                        margin-top: 15px;
                        text-align: center;
                    }
                }
            }
        }
    }
    &-form {
        margin: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        .form-control {
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            label {
                margin-left: 1.5rem;
                margin-bottom: 0;
                font-size: 1.4rem;
                line-height: 2rem;
                text-align: center;
                letter-spacing: -0.015em;
            }
        }
    }
}

input[type=checkbox] {
    font-size: inherit;
    margin: 0 1.2rem;
    transform: scale(2.3);
}