*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

.absLeft{
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
}
.absRight{
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
}


/* =10px */

@media only screen and (max-width: 1024px) {
    html {
        font-size: 56.25%;
    }
    /* =9px / 16px */
}

@media only screen and (max-width: 768px) {
    html {
        font-size: 50%;
    }
    /* =8px / 16px */
}

@media only screen and (max-width: 480px) {
    html {
        font-size: 47.25%;
    }
    /* =7px  / 16 px*/
}

body {
    font-size: 1.4rem;
    font-family: 'Montserrat', sans-serif;
    padding: 2.5rem 3.6rem;
    color: #444444;
    @media only screen and (max-width: 768px) {
        padding: 4rem;
    }
    #root{
        padding: 2rem 0;
        background-color: #f4eff9;
        border-radius: 40px;
        @media only screen and (max-width: 768px) {
            padding: 4rem 3rem;
        }
        @media only screen and (max-width: 425px) {
            padding: 4rem 1rem;
        }
    }
}

.marginTop {
    margin-top: 2rem;
}

.marginBottom {
    margin-bottom: 2rem;
}

.marginRight {
    margin-right: 2rem;
}

input:focus , select:focus{
    outline: none;
}
select{
    white-space: normal!important
}

.aviso{
    background-color: #e5be01;
    margin-top: 2rem;
    padding: 20px;
    text-align: center;
    border-radius: 100px;
}

.buttons-carrousel{
    margin: 0px;
    border: none;
    background-color: #E0E0E0;
    padding: 10px;
}

.ocultar-btns{
    display: none;
}

.mostrar-btns{
    display: block;
}

