input:not([type=checkbox]), select{
    background-color: #f4eff9;
    border: 2px solid #89288c;
    width: 100%;
    max-width: 100%;
    @media screen and (max-width: 768px) {
        width: 500px;
        max-width: 100%;
    }
    @media screen and (max-width: 425px) {
        width: 100%;
    }
}
input[type=text] ,input[type=number] {
    height: 6.7rem;
    border-radius: 3.5rem;
    text-align: center;
    font-size: 1.8rem;
}
.grid-container{
    input{
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        @media screen and (max-width: 425px) {
            width: 100%;
        }
        
    }
}

input[type=date] {
    height: 6.7rem;
    background: #f4eff9;
    border-radius: 3.5rem;
    text-align: center;
    font-size: 2.4rem;
    position: relative;
    &::placeholder {
        color: #444444;
        font-size: 1.8em;
        text-transform: uppercase;
    }
    &::-webkit-input-placeholder {
        color: #444444;
        font-size: 1.8em;
        text-transform: uppercase;
    }

    &::-webkit-calendar-picker-indicator{
        height: 20px;
        position: absolute;
        left: calc(75% - 10px);
        top: calc(50% - 12px);
        @media only screen and (max-width: 1024px){
            left: calc(75% - 22px);
            top: calc(50% - 12px);
        }
    }
}      

select {
    height: 6.7rem;
    padding: 0 3.5rem;   
    background-color: #f4eff9;
    background-image: url("../../images/drop-down.svg");
    background-repeat: no-repeat;
    background-position: center right 15px;
    background-size: 16px;
    border-radius: 3.5rem;
    text-align: center;
    font-size: 1.8rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    color: #89288c;
    font-weight: bold;
    option {
        color: #444444;
        height: 20px;
        text-decoration: underline;
        border-radius: 1.6rem;
        position: absolute;
      }
}

label {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 20px;
}



