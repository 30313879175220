.perfil {
    padding-top: 9.6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    text-align: center;

    h1 {
        margin-bottom: 1.5rem;
    }

    &-form {
        margin-top: 6.5rem;
        margin-bottom: 12.7rem;
        width: 100%;
        padding: 0 18rem;
        @media only screen and (max-width: 520px) {
            padding:0;
        }

        display: grid;
        grid-template-columns:  auto 1fr auto;
        align-items: center;


        position: relative;

        @media only screen and (max-width: 960px) {
            // display: block;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;

            .text-age {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                margin-top: 5rem;
                label {
                    margin-left: 2rem;
                }
            }
        }

        &_input{

            img {
                padding: .5rem;
                height: 5rem;
                width: 5rem;
            }
        }

        .text-age {
            input{
                max-width: 13rem;
                min-width: 13rem;
                width: 100%;
                
                &::placeholder {
                    color: #FFFFFF;
                    font-weight: normal;
                    opacity: 1;
                }
            }
        }
    }
}
