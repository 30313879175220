.content {
    width: 100%;
    // height: 45rem;

    padding: 0.5rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    &-text{
        margin-top: 1.7rem;
        font-size: 1.8rem;
        color: red;
        font-weight: 400;
        text-align: center;
        max-width: 98.2rem;
    }

}