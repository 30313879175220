@import "./base/base";
@import './components/button';
@import './components/card';
@import './components/input';
@import './components/link';
@import './components/text';
@import './layout/nav-bar';
@import './layout/centros';
@import './layout/contacto';
@import './layout/content';
@import './layout/diagnostico';
@import './layout/inicio';
@import './layout/perfil';
@import './layout/resultado';
@import './layout/seguro';
@import './layout/footer';
.inicio,
.seguro,
.perfil,
.diagnostico,
.centros {
    min-height: 650px;
}

.seguro,
.perfil,
.diagnostico,
.centros {
    background-image: url(../images/backgroun-comun.png);
    background-size: 100%;
    @media screen and (max-width: 768px){
        background-image: url(../images/background-top-movil.png), url(../images/background-bottom-movil.png);
        background-position: center 10%, center 90%;
        background-repeat: no-repeat, no-repeat;
    }
    @media (max-width: 425px){
        background-size: contain;
        background-position: 60% top;
    }
}
