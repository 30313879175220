.text {

    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2rem;
    /* or 111% */

    text-align: center;
    letter-spacing: -0.015em;

    color: #000000;
    h3 {
        margin-bottom: 1.5rem;
    }
    p {
        font-weight: 500;
    }
}

.cargando {
    height: 5rem;
    width: 30rem;
    background: #BDBDBD;
    color: black;
    border: 1px solid #000;
    text-align: center;
    margin-top: 3rem;
    border-radius: 1.6rem;
}