.centros {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &-title {
        margin-bottom: 6.3rem;
        font-size: 2.4rem;
        text-align: center;
    }
    &-form {
        display: grid;
        grid-template-columns: 1fr 5rem 1fr;
        grid-gap: 2rem;
        margin-bottom: 6.3rem;
        .horizontal-bar {
            &::after {
                bottom: 0;
                left: 50%;
                height: auto;
                transform: translate(50%, 0%);
            }
        }
        @media only screen and (max-width: 1100px) {
            // display: grid;
            // grid-template-columns: 1fr;
            display: block;
             :first-child {
                margin-bottom: 3rem;
            }
        }
    }
}