.diagnostico {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &-form {
        .flex-container{
            margin-top: 5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .flex-row{
                display: flex;
                flex-direction: row;
                @media only screen and (max-width: 768px) {
                    flex-direction: column;
                }
            }
        }
        @media only screen and (max-width: 1190px) {
            display: flex;
            flex-flow: column wrap;
            text-align: center;
            label {
                margin: unset!important;
                margin-top: auto!important;
            }
            select {
                margin: unset!important;
                margin-top: 20px!important;
            }
            &:not(:last-child) {
                border-right: none;
            }
        }
    }
    .form-control {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0 2rem;
        height: 14rem;
        width: 25%;
        @media only screen and (max-width: 1190px) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            text-align: center;
            height: auto;
            &:not(:last-child) {
                border-right: none;
            }
        }
        @media (max-width: 768px){
            width: 80%;
            margin: auto;
        }
        @media only screen and (max-width: 425px) {
            width: 100%;
            flex-flow: column;
            padding: 0;
            margin-bottom: 10px;
        }
    }
    h1 {
        margin-top: 20px;
        margin-bottom: 1.5rem;
        @media(max-width: 425px){
            text-align: center;
        }
    }
    p {
        margin: 2rem 0;
        padding: 0 6.5rem;
        font-size: 1.4rem;
        strong {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5rem;
            color: #18A0FB;
        }
    }
}