.footer {
    padding: 0 3.6rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 425px){
        flex-direction: column;
    }

    &-logo{
        max-width: 72%;
        @media (max-width: 425px){
            width: 100%;
            max-width: 100%;
            margin-bottom: 10px
        }
    }
    &-nav {
        display: flex;
        align-items: center;           


        &--list{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media only screen and (max-width: 520px) {
                flex-direction: column;
                justify-content: flex-end;
            }
             
             
            &_item {
                list-style: none;
                &:last-child{
                    margin-left: 2rem;        
                }
                @media only screen and (max-width: 520px) {
                    &:last-child{
                        margin-left: 0rem;        
                    }   
                }
                a{
                    color: #444444 !important;
                }
           }

           
        }
    }



}